.section-tooltip-image {
  background-color: var(--mantine-color-gray-2);
  border: solid 6px white;
  border-radius: 0.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  display: flex;
  align-items: center;
}
.card-section {
  border-radius: 8px;
}
.text {
  white-space: normal;
}
